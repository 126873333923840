import React from "react"
import { fieldType, TYPE_REGISTER_FIELD } from "helpers/register/register-template"
import { Input, Select } from "assets/form/"
import styles from "./register.module.scss"
import { IHandleChangeField } from "./Register"
import { USER_TYPE } from "@nectar/lib-types/build/user/user"

interface props {
    fieldName: string
    fieldType: fieldType
    onChange?: IHandleChangeField
}

const Field: React.FC<props> = ({ fieldName, fieldType, onChange }) => {
    let capitalizedField = fieldName
    if (capitalizedField.length > 0) {
        capitalizedField = capitalizedField[0].toUpperCase() + capitalizedField.slice(1)
    }
    switch (fieldType.type) {
        case TYPE_REGISTER_FIELD.STRING: {
            return (
                <Input
                    type="text"
                    placeholder={capitalizedField}
                    className={styles.field}
                    onChange={e => onChange && onChange(e.target.value, fieldType)}
                />
            )
        }
        case TYPE_REGISTER_FIELD.NUMBER: {
            return (
                <Input
                    type="number"
                    placeholder={capitalizedField}
                    className={styles.field}
                    onChange={e =>
                        onChange && onChange(Number(e.target.value), fieldType)
                    }
                />
            )
        }
        case TYPE_REGISTER_FIELD.CHOICE_USER_TYPE: {
            return (
                <Select
                    placeholder={capitalizedField}
                    className={styles.field}
                    onChange={e => onChange && onChange(e, fieldType)}
                    options={[
                        {
                            text: "Freelance",
                            value: USER_TYPE.FREELANCE,
                        },
                        {
                            text: "Entreprise",
                            value: USER_TYPE.COMPANY,
                        },
                    ]}
                />
            )
        }
    }
}

export default Field
