import { AbstractLogin, loginReturn } from "./abstract-login"

export class EmailRegisterLogin extends AbstractLogin {
    public async login(mail: string, password: string): Promise<loginReturn> {
        const useCredential = await this.auth().createUserWithEmailAndPassword(
            mail,
            password,
        )
        return this.loginWithFirebase(useCredential)
    }
}
