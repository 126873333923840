import { Reducer } from "redux"
import {
    LOADING_STATE,
    ReducerType,
    MissionsActions,
    ACTIONS_TYPES,
} from "./missions-types"

const defaultState: ReducerType = {
    availablesMissions: [],
    availablesMissions_state: LOADING_STATE.NOT_LOADED,
    likedMissions: [],
    likedMissions_state: LOADING_STATE.NOT_LOADED,
}

export const MissionReducer: Reducer<ReducerType, MissionsActions> = (
    state = defaultState,
    action,
) => {
    switch (action.type) {
        case ACTIONS_TYPES.UPDATE_STATE: {
            return {
                ...state,
                [action.payload.field]: action.payload.value,
            }
        }
        case ACTIONS_TYPES.UPDATE_MISSIONS: {
            return {
                ...state,
                [action.payload.field]: action.payload.missions,
                [action.payload.stateField]: LOADING_STATE.LOADED,
            }
        }
        case ACTIONS_TYPES.SET_OUTDATED_MISSION: {
            return defaultState
        }
        default:
            return state
    }
}
