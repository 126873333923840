import { CSSProperties } from "react"

export interface BaseProps {
    className?: string
    style?: CSSProperties
}

// Only get keys to get importants values
const baseKeys: BaseProps = {
    className: "",
    style: {},
}

// Extract applicable props
export const applicableProps = <TProps extends BaseProps>(
    props: TProps,
    className?: string,
): BaseProps => {
    let constructedProps: BaseProps = {}
    ;(Object.keys(baseKeys) as Array<keyof BaseProps>).forEach(keyName => {
        if (className && keyName === "className") {
            constructedProps = {
                ...constructedProps,
                className: `${className}${props.className ? " " + props.className : ""}`,
            }
            return
        }

        if (props[keyName] === undefined) {
            return
        }
        constructedProps = {
            ...constructedProps,
            [keyName]: props[keyName],
        }
    })

    return constructedProps
}
