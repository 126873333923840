import { useState } from "react"

const BASE_STATE = "__persistant_state_"

export const usePersistantState = <T>(
    defaultValue: T,
    keyName: string,
): [T, (newData: T) => void] => {
    const previousSessionValue = localStorage.getItem(BASE_STATE + keyName)

    const [data, setData] = useState<T>(
        previousSessionValue === null ? defaultValue : JSON.parse(previousSessionValue),
    )

    const onSetData = (newData: T) => {
        setData(newData)
        localStorage.setItem(BASE_STATE + keyName, JSON.stringify(newData))
    }

    return [data, onSetData]
}
