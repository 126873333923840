import { combineReducers, createStore } from "redux"
import { profileReducer } from "./profile/profile-reducer"
import { MissionReducer } from "./missions/missions-reducer"

const rootReducer = combineReducers({
    profile: profileReducer,
    missions: MissionReducer,
})

export type StoreType = ReturnType<typeof rootReducer>

export const store = createStore(
    rootReducer,
    (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
        (window as any).__REDUX_DEVTOOLS_EXTENSION__(),
)
