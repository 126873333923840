import { UserRegisterInterface } from "@nectar/lib-types/build/user/user"
import Axios from "axios"
import { API_MAIN_SERVER_URL } from "config/globals"
import { saveToken } from "helpers/jwt/save-token"
import { store } from "redux/store"
import { setConnected } from "redux/profile/profile-action"
import { checkIsConnected } from "helpers/login/check-connected"

export const registerUser = async (
    token: string,
    payload: Partial<UserRegisterInterface>,
) => {
    try {
        const { data } = await Axios.post(API_MAIN_SERVER_URL + "/utils/register", {
            token,
            data: payload,
        })
        if (!data.token) {
            throw Error("Invalid response fron server. Can't connect you")
        }
        store.dispatch(setConnected(true))
        checkIsConnected()
        saveToken(data.token)
    } catch (errorRegister) {
        if (errorRegister.response?.data?.message) {
            throw Error(errorRegister.response.data.message)
        } else {
            throw Error(errorRegister)
        }
    }
}
