import { FacebookLogin } from "./facebook-login"
import { GoogleLogin } from "./google-login"
import { MailLogin } from "./mail-login"
import { EmailRegisterLogin } from "./email-register-login"

export const loginProviders = {
    mail: () => new MailLogin(),
    google: () => new GoogleLogin(),
    facebook: () => new FacebookLogin(),
    mailRegister: () => new EmailRegisterLogin(),
}
