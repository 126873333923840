import { Reducer } from "redux"
import { ReducerType, Actions, ACTIONS } from "./profile-types"

const defaultState: ReducerType = { connected: false, connectLoading: true }

export const profileReducer: Reducer<ReducerType, Actions> = (
    state = defaultState,
    action,
) => {
    switch (action.type) {
        case ACTIONS.UPDATE_USER:
            return {
                ...action.payload,
                connectLoading: false,
                connected: true,
            }
        case ACTIONS.SET_CONNECTED:
            return {
                ...state,
                connected: action.connected,
                connectLoading: false,
            }
        default:
            return state
    }
}
