import { UserInterface } from "@nectar/lib-types/build/user/user"
import { Actions, ACTIONS } from "./profile-types"

export const updateUser = (user: UserInterface): Actions => ({
    type: ACTIONS.UPDATE_USER,
    payload: user,
})

export const setConnected = (connected: boolean): Actions => ({
    type: ACTIONS.SET_CONNECTED,
    connected,
})
