import { UserInterface } from "@nectar/lib-types/build/user/user"

export type ReducerType = Partial<UserInterface> & {
    connected: boolean
    connectLoading: boolean
}

export enum ACTIONS {
    UPDATE_USER = "PROFILE/UPDATE_USER",
    SET_CONNECTED = "PROFILE/SET_CONNECTED",
}

interface UpdateUserAction {
    type: ACTIONS.UPDATE_USER
    payload: UserInterface
}

interface SetConnectedAction {
    type: ACTIONS.SET_CONNECTED
    connected: boolean
}

export type Actions = UpdateUserAction | SetConnectedAction
