import React from "react"
import styles from "./register.module.scss"

interface Props {
    title: string
    logo?: any
}

const RegisterTitle: React.FC<Props> = ({ title, logo }) => {
    return (
        <div>
            {logo && (
                <div
                    className={styles.titleLogo}
                    style={{
                        backgroundImage: `url("${logo}")`,
                    }}
                />
            )}
            <div className={styles.sectionTitle}>{title}</div>
        </div>
    )
}
export default RegisterTitle
