import React, { ChangeEvent } from "react"
import styles from "./input.module.scss"
import { BaseProps, applicableProps } from "helpers/base-component/base-component"

interface props extends BaseProps {
    value?: string
    onChange?: (value: ChangeEvent<HTMLInputElement>) => void
    placeholder?: string
    type?: string
}

export const Input: React.FC<props> = props => {
    const baseProps = applicableProps(props, styles.input)

    return (
        <input
            {...baseProps}
            placeholder={props.placeholder}
            value={props.value}
            onChange={props.onChange}
            type={props.type}
        />
    )
}
