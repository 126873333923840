export const BASE_URL_PLATFORM = "/platform"

export const routes = {
    login: "/login",
    settings: {
        index: `${BASE_URL_PLATFORM}/settings`,
        freelance: `${BASE_URL_PLATFORM}/settings/freelance`,
        status: `${BASE_URL_PLATFORM}/settings/status`,
        company: `${BASE_URL_PLATFORM}/settings/company`,
    },
    dashboard: {
        index: `${BASE_URL_PLATFORM}/dashboard`,
    },
    missions: {
        index: `${BASE_URL_PLATFORM}/missions`,
        mission: `${BASE_URL_PLATFORM}/mission`,
    },
    user: {
        index: `${BASE_URL_PLATFORM}/user`,
    },
}
