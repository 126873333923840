import React, { useState, useRef } from "react"

import { useDetectClickOutside, useNotification } from "helpers/hooks"

import styles from "./select-multiple.module.scss"
import { BaseProps, applicableProps } from "helpers/base-component"
import Arrow from "assets/logos/ArrowMenu.svg"
import { FiX, FiCheck } from "react-icons/fi"

interface Item {
    key: string
    value: string
}

interface props extends BaseProps {
    options?: Array<Item>
    value?: Array<string>
    limit?: number
    onChange?: (value: Array<string>) => void
}

export const SelectMultiple: React.FC<props> = props => {
    const { value, options, onChange, limit } = props
    const rootProps = applicableProps(props, styles.rootElm)
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const { addNotification } = useNotification()

    const handleRemoveElement = (key: string) => {
        const newValue = value?.filter(elm => elm !== key) || []
        onChange && onChange(newValue)
    }

    const handleTriggerElement = (key: string) => {
        if (!value || !options) {
            return
        }

        if (value.some(elm => elm === key)) {
            handleRemoveElement(key)
        } else {
            if (limit && value.length >= limit) {
                addNotification(`Limité à ${limit} choix`, { type: "error" })
                return
            }
            onChange && onChange([...value, key])
        }
    }

    const selectRef = useRef<HTMLDivElement>(null)
    useDetectClickOutside(selectRef, isOpen, () => setIsOpen(false))

    return (
        <div {...rootProps} ref={selectRef}>
            <div className={styles.inputElement} onClick={() => setIsOpen(!isOpen)}>
                <div className={styles.listSelected}>
                    {value &&
                        value.map(element => (
                            <div
                                className={styles.elementValue}
                                key={element}
                                onClick={e => {
                                    e.stopPropagation()
                                    handleRemoveElement(element)
                                }}
                            >
                                <div>
                                    {(options &&
                                        options.find(elm => elm.key === element)
                                            ?.value) ||
                                        options ||
                                        ""}
                                </div>
                                <div className={styles.closeElm}>
                                    <FiX />
                                </div>
                            </div>
                        ))}
                </div>
                <div className={styles.selectMore} onClick={() => setIsOpen(!isOpen)}>
                    <div
                        style={{
                            maskImage: `url(${Arrow})`,
                            WebkitMaskImage: `url(${Arrow})`,
                            transform: `rotate(${isOpen ? 90 : -90}deg)`,
                        }}
                    />
                </div>
            </div>
            {isOpen && (
                <div className={styles.dropdown}>
                    {options &&
                        options.map(option => (
                            <div
                                className={styles.itemSelectable}
                                key={option.key}
                                onClick={() => handleTriggerElement(option.key)}
                            >
                                <div
                                    className={styles.isSelected}
                                    style={{
                                        display:
                                            value && value.some(elm => elm === option.key)
                                                ? "flex"
                                                : "none",
                                    }}
                                >
                                    <FiCheck />
                                </div>
                                <div>{option.value}</div>
                            </div>
                        ))}
                </div>
            )}
        </div>
    )
}
