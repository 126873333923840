import { USER_TYPE } from "@nectar/lib-types/build"

interface getNameArgs {
    userType?: USER_TYPE
    name?: string
    lastname?: string
    companyName?: string
}

export const getName = ({
    lastname,
    name,
    companyName,
    userType,
}: getNameArgs): string => {
    let finalName = "Harry Potter"
    if (name && lastname) {
        finalName = `${name} ${lastname}`
    }

    if (userType === USER_TYPE.COMPANY && companyName) {
        finalName = companyName
    }

    return finalName
}
