import { store } from "redux/store"
import { setConnected, updateUser } from "redux/profile/profile-action"
import { getToken } from "helpers/jwt/get-token"
import { Laxios } from "helpers/axios"

export const checkIsConnected = async () => {
    if (getToken() === null) {
        store.dispatch(setConnected(false))
        return
    }

    try {
        const data = await Laxios().apply(axios => axios.get("/user/me"))
        store.dispatch(updateUser(data.data))
    } catch {}
}
