import React, { useState } from "react"
import { BaseProps, applicableProps } from "helpers/base-component/base-component"
import { DropDown } from "assets/dropdown"
import styles from "./select.module.scss"

interface props extends BaseProps {
    // value?: string
    onChange?: (value: string) => void
    placeholder?: string
    options?: {
        value: string
        text: string
    }[]
}

export const Select: React.FC<props> = props => {
    const baseProps = applicableProps(props, styles.select)

    const [value, setValue] = useState<string | undefined>(undefined)

    const handleChange = (value: string) => {
        setValue(value)
        props.onChange && props.onChange(value)
    }

    if (!props.options) {
        return <div></div>
    }

    return (
        <DropDown
            {...baseProps}
            options={props.options.map(option => ({
                key: option.value,
                value: option.text,
            }))}
            onChange={handleChange}
            value={value}
        >
            Status
        </DropDown>
    )
}
