import React from "react"
import styles from "./minimal-user-card.module.scss"
import ArrowLogo from "assets/logos/ArrowMenu.svg"
import { BaseProps, applicableProps } from "helpers/base-component/base-component"
import { useInitials } from "helpers/hooks/use-initials"

interface props extends BaseProps {
    name: string
    tag: string
    onClick?: () => void
}
export const MinimalUserCard: React.FC<props> = props => {
    const { name, tag, onClick } = props
    const initials = useInitials(name)

    const propsRoot = applicableProps(props, styles.root)

    return (
        <div {...propsRoot} onClick={onClick}>
            <div className="columns is-mobile is-vcentered">
                <div className="column is-narrow">
                    <div className={styles.initials}>
                        <div className={styles.initialsLetters}>{initials}</div>
                    </div>
                </div>
                <div className="column">
                    <div>
                        <div className={styles.name}>{name}</div>
                        <div className={styles.containerTagType}>
                            <div className={styles.tagType}>{tag}</div>
                        </div>
                    </div>
                </div>
                <div className="column is-narrow">
                    <div
                        className={styles.arrow}
                        style={{ backgroundImage: `url(${ArrowLogo})` }}
                    ></div>
                </div>
            </div>
        </div>
    )
}
