import React, { useState } from "react"
import { loginProviders } from "../../helpers/login/providers"
import { Input, SoftButton, Button } from "assets/form"
import styles from "./login.module.scss"
import Register from "./register/Register"
import { loginReturn } from "helpers/login/providers/abstract-login"
import { useHistory } from "react-router-dom"
import { routes } from "helpers/routes"
import { CircularProgress } from "@material-ui/core"
import { FiArrowRight } from "react-icons/fi"

type LoginProvidersType = keyof typeof loginProviders

enum TAB {
    LOGIN,
    SIGNIN,
}

const Login: React.FC = () => {
    const [tab, setTab] = useState<TAB>(TAB.LOGIN)
    const [showRegister, setShowRegister] = useState<boolean>(false)
    const [token, setToken] = useState<string>("")

    const [mail, setMail] = useState<string>("")
    const [password, setPassword] = useState<string>("")
    const [repeatPassword, setRepeatPassword] = useState<string>("")
    const [loading, setLoading] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>("")
    const history = useHistory()

    const login = async (provider: LoginProvidersType) => {
        setLoading(true)
        try {
            let returnLogin: loginReturn
            switch (provider) {
                case "facebook":
                    returnLogin = await loginProviders.facebook().login()
                    break
                case "google":
                    returnLogin = await loginProviders.google().login()
                    break
                case "mail":
                    returnLogin = await loginProviders.mail().login(mail, password)
                    break
                case "mailRegister":
                    if (password !== repeatPassword) {
                        throw Error("Les mots de passes sont différents")
                    } else if (password.length < 6) {
                        throw Error("Le mot de passe est trop court")
                    }
                    returnLogin = await loginProviders
                        .mailRegister()
                        .login(mail, password)
                    break
                default:
                    setLoading(false)
                    throw Error("Bad provider")
            }
            setLoading(false)
            if (returnLogin.status === "REGISTER") {
                setShowRegister(true)
                setToken(returnLogin.token)
            } else {
                history.push(routes.settings.index)
            }
        } catch (error) {
            setLoading(false)
            console.error(error)
            if (error.message) {
                setErrorMessage(error.message)
            } else {
                setErrorMessage("Quelquechose c'est mal passé...")
            }
            return
        }
    }

    if (showRegister && token) {
        return <Register token={token} />
    }

    return (
        <div className={styles.root}>
            <div className={styles.centered}>
                <div className={styles.title}>Nectar</div>
                <div className={styles.container}>
                    <div className={styles.panel}>
                        {errorMessage && (
                            <div className={styles.errorMessageContainer}>
                                <div className={styles.errorMessage}>
                                    Erreur : {errorMessage}
                                </div>
                            </div>
                        )}
                        <div className="columns">
                            {loading ? (
                                <div className={styles.spinner}>
                                    <CircularProgress style={{ color: "#1f1f1f" }} />
                                </div>
                            ) : (
                                <>
                                    <div className="column">
                                        <div className={styles.selector}>
                                            <SoftButton
                                                active={tab === TAB.LOGIN}
                                                className={styles.loginButton}
                                                onClick={() => setTab(TAB.LOGIN)}
                                            >
                                                Connexion
                                            </SoftButton>
                                            <SoftButton
                                                active={tab === TAB.SIGNIN}
                                                onClick={() => setTab(TAB.SIGNIN)}
                                            >
                                                Inscription
                                            </SoftButton>
                                        </div>
                                        <div className={styles.inputs}>
                                            <Input
                                                placeholder="Email"
                                                className={styles.input}
                                                value={mail}
                                                onChange={e => setMail(e.target.value)}
                                                type="mail"
                                            />
                                            <Input
                                                placeholder="Mot de passe"
                                                className={styles.input}
                                                type="password"
                                                value={password}
                                                onChange={e =>
                                                    setPassword(e.target.value)
                                                }
                                            />
                                            {tab === TAB.SIGNIN && (
                                                <Input
                                                    placeholder="Répéter le mot de passe"
                                                    className={styles.input}
                                                    type="password"
                                                    value={repeatPassword}
                                                    onChange={e =>
                                                        setRepeatPassword(e.target.value)
                                                    }
                                                />
                                            )}
                                            <div className={styles.buttonLoginContainer}>
                                                {tab === TAB.LOGIN ? (
                                                    <Button
                                                        onClick={() => login("mail")}
                                                        type="primary"
                                                        icon={() => <FiArrowRight />}
                                                    >
                                                        Valider
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        onClick={() =>
                                                            login("mailRegister")
                                                        }
                                                        type="primary"
                                                        icon={() => <FiArrowRight />}
                                                    >
                                                        Continuer
                                                    </Button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="column is-narrow is-vcentered is-hidden-touch">
                                        <div className={styles.lineSeparator} />
                                    </div>
                                    <div className="column is-narrow is-vcentered is-hidden-desktop">
                                        <div className={styles.lineSeparatorHorizontal} />
                                    </div>
                                    <div className="column">
                                        <div className={styles.titleLoginVia}>
                                            {tab === TAB.LOGIN
                                                ? "Connexion via"
                                                : "Inscription via"}
                                        </div>
                                        <div className={styles.providers}>
                                            <Button
                                                onClick={() => login("google")}
                                                color="#4285f4"
                                                colorActive="#6da4ff"
                                                textColor="white"
                                                className={styles.buttonProvider}
                                                icon={() => <FiArrowRight />}
                                            >
                                                Google
                                            </Button>
                                            <Button
                                                onClick={() => login("facebook")}
                                                color="#4267b2"
                                                colorActive="#5c80ca"
                                                textColor="white"
                                                className={styles.buttonProvider}
                                                icon={() => <FiArrowRight />}
                                            >
                                                Facebook
                                            </Button>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login
