import React, { CSSProperties, useState } from "react"
import { BaseProps, applicableProps } from "helpers/base-component/base-component"
import styles from "./button.module.scss"

interface baseButtonProps extends BaseProps {
    active?: boolean
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

interface softButtonProps extends baseButtonProps {}

export const SoftButton: React.FC<softButtonProps> = props => {
    const propsApply = applicableProps(props, styles.softButton)

    if (props.active && propsApply.className) {
        propsApply.className = propsApply.className + " " + styles.softButtonActive
    }

    return (
        <button {...propsApply} onClick={props.onClick}>
            {props.children}
        </button>
    )
}

interface buttonProps extends baseButtonProps {
    type?: "primary" | "secondary" | "tertiary"
    color?: string
    colorActive?: string
    img?: any
    icon?: () => JSX.Element
    alt?: string
    textColor?: string
    textColorActive?: string
}

interface specialStyle extends CSSProperties {
    backgroundColor?: string
    color?: string
}

export const Button: React.FC<buttonProps> = props => {
    const [isHover, setIsHover] = useState<boolean>(false)

    let buttonClass = styles.button
    if (props.type === "primary") {
        buttonClass = styles.primaryButton
    }

    if (props.type === "tertiary") {
        buttonClass = styles.tertiaryButton
    }

    const propsApply = applicableProps(props, buttonClass)

    let style: specialStyle = {}

    if (!props.img && !props.icon) {
        style = {
            ...style,
            padding: "10px 16px",
        }
    }

    if (props.color) {
        style = {
            ...style,
            backgroundColor: props.color,
        }
    }

    if (props.colorActive && (props.active || isHover)) {
        style = {
            ...style,
            backgroundColor: props.colorActive,
        }
    }

    if (props.textColor) {
        style.color = props.textColor
    }

    if (props.textColorActive && (props.active || isHover)) {
        style.color = props.textColorActive
    }

    return (
        <button
            {...propsApply}
            style={style}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            onClick={props.onClick}
        >
            <div className="columns is-mobile is-vcentered is-gapless">
                <div className="column">{props.children}</div>
                {props.img && (
                    <div className="column is-narrow">
                        <div className={styles.imgContainer}>
                            <img
                                src={props.img}
                                alt={props.alt}
                                className={styles.logoImg}
                            />
                        </div>
                    </div>
                )}
                {!props.img && props.icon && (
                    <div className="column is-narrow">
                        <div className={styles.imgContainer}>
                            <div className={styles.icon}>{props.icon()}</div>
                        </div>
                    </div>
                )}
            </div>
        </button>
    )
}
