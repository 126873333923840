import React, { useRef } from "react"
import { useWindowWidth, usePersistantState, useDetectClickOutside } from "helpers/hooks"
import styles from "./menu.module.scss"
import ArrowMenu from "assets/logos/ArrowMenu.svg"

interface props {
    title?: string
    links?: { key: string | number; item: React.FC }[]
    bottom?: React.FC
}

export const Menu: React.FC<props> = ({ children, title, links, bottom: Bottom }) => {
    const width = useWindowWidth()
    const isMobile = width < 600
    const [showMenu, setShowMenu] = usePersistantState<boolean>(!isMobile, "show_menu")
    const handleClickOpen = (e: React.MouseEvent) => {
        if (!showMenu) {
            e.stopPropagation()
            setShowMenu(true)
        }
    }
    const navElm = useRef<HTMLDivElement>(null)

    useDetectClickOutside(navElm, isMobile && showMenu, () => setShowMenu(false))

    return (
        <div>
            <nav
                className={styles.nav}
                style={{
                    left: showMenu ? 0 : -256,
                }}
                onClick={handleClickOpen}
                ref={navElm}
            >
                <div className={styles.navContainer}>
                    <div
                        className={styles.triggerMenu}
                        onClick={() => setShowMenu(!showMenu)}
                        style={{
                            backgroundImage: `url(${ArrowMenu})`,
                            transform: `rotate(${showMenu ? 180 : 0}deg)`,
                        }}
                    ></div>
                    <div className={styles.containerTop}>
                        <div className={styles.titleDiv}>
                            <div className={styles.title}>{title}</div>
                        </div>
                        <div className={styles.links}>
                            {links &&
                                links.map(link => (
                                    <div key={link.key} className={styles.item}>
                                        <link.item />
                                    </div>
                                ))}
                        </div>
                    </div>
                    <div
                        className={styles.containerBot}
                        style={{
                            opacity: showMenu ? 1 : 0,
                        }}
                    >
                        {Bottom && <Bottom />}
                    </div>
                </div>
            </nav>
            <div
                className={styles.content}
                style={{
                    paddingLeft: isMobile ? 60 : showMenu ? 330 : 74,
                }}
            >
                {children}
            </div>
        </div>
    )
}
