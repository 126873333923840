import { initializeApp } from "firebase/app"

const firebaseConfig = {
    apiKey: "AIzaSyBh6XXgvnHKwVElEm4fKSrh-eLcQh5J6VQ",
    authDomain: "nectar-260420.firebaseapp.com",
    databaseURL: "https://nectar-260420.firebaseio.com",
    projectId: "nectar-260420",
    storageBucket: "nectar-260420.appspot.com",
    messagingSenderId: "246897585237",
    appId: "1:246897585237:web:3a42e9c4d8570552a978dc",
    measurementId: "G-XWNH10E5CE",
}

initializeApp(firebaseConfig)
