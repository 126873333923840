import React from "react"
import { Provider } from "react-redux"
import { store } from "./redux/store"
import { checkIsConnected } from "helpers/login/check-connected"
import "./App.css"
import Router from "./pages/Router"
import { ToastProvider } from "react-toast-notifications"
import "bulma"

const App: React.FC = () => {
    checkIsConnected()
    return (
        <div className="App">
            <Provider store={store}>
                <ToastProvider>
                    <Router />
                </ToastProvider>
            </Provider>
        </div>
    )
}

export default App
