"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FreelanceTags = [
    {
        id: "DEVELOPPER",
        value: {
            default: "Développeur",
        },
    },
    {
        id: "DESIGNER",
        value: {
            default: "Designer",
        },
    },
    {
        id: "GRAPHIST",
        value: {
            default: "Graphiste",
        },
    },
    {
        id: "COMMUNITY_MANAGER",
        value: {
            default: "Community Manager",
        },
    },
    {
        id: "INFLUENCER",
        value: {
            default: "Influenceur",
        },
    },
];
