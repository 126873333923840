import { AbstractLogin } from "./abstract-login"

export class MailLogin extends AbstractLogin {
    public async login(mail: string, password: string) {
        const userCredebtials = await this.auth().signInWithEmailAndPassword(
            mail,
            password,
        )
        return this.loginWithFirebase(userCredebtials)
    }
}
