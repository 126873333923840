import React from "react"
import { HashRouter, Route, Link } from "react-router-dom"
import Login from "./login/Login"
import RoutePlatform from "./platform/PlatformRoute"
import { routes, BASE_URL_PLATFORM } from "helpers/routes"

const Router: React.FC = () => {
    return (
        <HashRouter>
            <Route exact path="/">
                <Link to={routes.login}>Login</Link>
                <Link to={routes.settings.index}>Profil</Link>
            </Route>
            <Route exac path={routes.login}>
                <Login />
            </Route>
            <Route path={BASE_URL_PLATFORM}>
                <RoutePlatform />
            </Route>
        </HashRouter>
    )
}

export default Router
