import { MissionInterface } from "@nectar/lib-types/build"
import { Action } from "redux"

export enum LOADING_STATE {
    NOT_LOADED = "NOT_LOADED",
    LOADING = "LOADING",
    LOADED = "LOADED",
    ERROR = "ERROR",
}

export enum ACTIONS_TYPES {
    UPDATE_STATE = "MISSIONS/UPDATE_STATE",
    UPDATE_MISSIONS = "MISSIONS/UPDATE_MISSION",
    SET_OUTDATED_MISSION = "MISSION/SET_OUTDATED",
}

export interface BaseMissionsState {
    availablesMissions_state: LOADING_STATE
    likedMissions_state: LOADING_STATE
}

export interface BaseMissionsList {
    availablesMissions: MissionInterface[]
    likedMissions: MissionInterface[]
}

export interface ReducerType extends BaseMissionsState, BaseMissionsList {}

export interface UpdateStatePayload extends Action {
    type: ACTIONS_TYPES.UPDATE_STATE
    payload: {
        field: keyof BaseMissionsState
        value: LOADING_STATE
    }
}

export interface UpdateMissionPayload extends Action {
    type: ACTIONS_TYPES.UPDATE_MISSIONS
    payload: {
        field: keyof BaseMissionsList
        stateField: keyof BaseMissionsState
        missions: MissionInterface[]
    }
}

export interface SetOutdatedMissionPayload extends Action {
    type: ACTIONS_TYPES.SET_OUTDATED_MISSION
}

export type MissionsActions =
    | UpdateStatePayload
    | UpdateMissionPayload
    | SetOutdatedMissionPayload
