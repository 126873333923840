import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import Field from "./Field"
import styles from "./register.module.scss"
import { UserRegisterInterface } from "@nectar/lib-types/build/user/user"
import { registerUser } from "helpers/register/register"
import RegisterTitle from "./RegisterTitle"
import WhiteArrow from "assets/logos/WhiteRightArrow.svg"
import { routes } from "helpers/routes"

import { registerTemplate, fieldType } from "helpers/register/register-template"

export type IHandleChangeField = <T extends fieldType>(
    data: Required<T["default"]>,
    fieldInfos: T,
) => void

interface props {
    token: string
}
const Register: React.FC<props> = ({ token }) => {
    const [fieldsData, setFieldData] = useState<Partial<UserRegisterInterface>>({})
    const history = useHistory()

    // Handle initial state
    useEffect(() => {
        let fieldsSelected: {
            fieldRegister: string
            defaultValue: any
        }[] = []

        for (let section in registerTemplate) {
            for (let field in registerTemplate[section].fields) {
                const fieldInfos = registerTemplate[section].fields[field]
                if (fieldInfos.default) {
                    fieldsSelected = [
                        ...fieldsSelected,
                        {
                            defaultValue: fieldInfos.default,
                            fieldRegister: fieldInfos.fieldRegisterName,
                        },
                    ]
                }
            }
        }

        setFieldData(
            fieldsSelected.reduce((prev, curr) => {
                return {
                    ...prev,
                    [curr.fieldRegister]: curr.defaultValue,
                }
            }, {}),
        )
    }, [])

    const handleChangeField: IHandleChangeField = (data, fieldInfos) => {
        setFieldData({
            ...fieldsData,
            [fieldInfos.fieldRegisterName]: data,
        })
    }

    const handleSubmit = async () => {
        try {
            await registerUser(token, fieldsData)
            history.push(routes.settings.index)
        } catch (e) {
            alert(e)
        }
    }

    return (
        <div className={styles.root}>
            <div className={styles.vcentered}>
                <div className={styles.container}>
                    <div className={styles.title}>Qui est-tu ?</div>
                    <div className={styles.fields}>
                        <div className="columns">
                            {Object.keys(registerTemplate).map(section => (
                                <div className="column is-4" key={section}>
                                    <RegisterTitle
                                        title={section}
                                        logo={registerTemplate[section].logo}
                                    />
                                    <div>
                                        {Object.keys(
                                            registerTemplate[section].fields,
                                        ).map(field => (
                                            <div key={`${section}_${field}`}>
                                                <Field
                                                    fieldName={field}
                                                    fieldType={
                                                        registerTemplate[section].fields[
                                                            field
                                                        ]
                                                    }
                                                    onChange={handleChangeField}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={styles.goButtonContainer}>
                        <div className={styles.goButton} onClick={handleSubmit}>
                            <div className={styles.buttonText}>C'est parti !</div>
                            <div
                                className={styles.arrowButton}
                                style={{ backgroundImage: `url("${WhiteArrow}")` }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Register
