import React, { useRef, useState } from "react"
import styles from "./dropdown.module.scss"
import { BaseProps, applicableProps } from "helpers/base-component/base-component"
import { useDetectClickOutside } from "helpers/hooks"
import Arrow from "assets/logos/ArrowMenu.svg"

interface Option<T extends string | number> {
    key: T
    value: string
}

interface props<T extends string | number> extends BaseProps {
    children: string
    options: Option<T>[]
    theme?: "primary"
    onChange?: (value: T) => void
    value?: T
}

export const DropDown = <T extends string | number>(props: props<T>) => {
    const { children, options, theme, value, onChange } = props
    const newProps = applicableProps(props, styles[theme ? "root_" + theme : "root"])

    const [open, setOpen] = useState<boolean>(false)

    const refElement = useRef<HTMLDivElement>(null)

    useDetectClickOutside(refElement, open, () => {
        setOpen(false)
    })

    const handleClick = (element: T) => {
        setOpen(false)
        onChange && onChange(element)
    }

    return (
        <div {...newProps} ref={refElement}>
            <div className={styles.titleDiv} onClick={() => setOpen(!open)}>
                <div className={styles.title}>
                    {(value && options.find(elm => elm.key === value))?.value || children}
                </div>
                <div
                    className={styles[theme ? "arrow_" + theme : "arrow"]}
                    style={{
                        maskImage: `url(${Arrow})`,
                        WebkitMaskImage: `url(${Arrow})`,
                        transform: `rotate(${open ? 90 : -90}deg)`,
                    }}
                />
            </div>
            <div
                className={styles.elements}
                style={{
                    display: open ? "block" : "none",
                }}
            >
                {options.map(elm => (
                    <div
                        key={elm.key}
                        className={styles.dropElement}
                        onClick={() => handleClick(elm.key)}
                    >
                        {elm.value}
                    </div>
                ))}
            </div>
        </div>
    )
}
