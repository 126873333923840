import { UserRegisterInterface, USER_TYPE } from "@nectar/lib-types/build/user/user"
import IdentityLogo from "assets/logos/LogoIdentity.svg"
import InformationLogo from "assets/logos/LogoInformation.svg"
import LogoContact from "assets/logos/LogoContact.svg"

export enum TYPE_REGISTER_FIELD {
    STRING = "string",
    NUMBER = "number",
    CHOICE_USER_TYPE = "choice_user_type",
}

export type fieldType = (
    | {
          type: TYPE_REGISTER_FIELD.STRING
          default?: string
      }
    | {
          type: TYPE_REGISTER_FIELD.NUMBER
          default?: number
      }
    | {
          type: TYPE_REGISTER_FIELD.CHOICE_USER_TYPE
          default?: string
      }
) & {
    required?: boolean
    fieldRegisterName: keyof UserRegisterInterface
}

const stringField = (fieldRegisterName: keyof UserRegisterInterface): fieldType => ({
    type: TYPE_REGISTER_FIELD.STRING,
    required: true,
    fieldRegisterName: fieldRegisterName,
})

export const registerTemplate: {
    [key: string]: {
        logo?: any
        fields: { [key: string]: fieldType }
    }
} = {
    identité: {
        logo: IdentityLogo,
        fields: {
            nom: stringField("lastname"),
            prénom: stringField("name"),
            "date de naissance (jj/mm/aaaa)": stringField("birth"),
        },
    },
    informations: {
        logo: InformationLogo,
        fields: {
            "Freelance ou entreprise": {
                type: TYPE_REGISTER_FIELD.CHOICE_USER_TYPE,
                fieldRegisterName: "userType",
                required: true,
                default: USER_TYPE.FREELANCE,
            },
            addresse: stringField("address"),
            ville: stringField("city"),
            "code postal": stringField("zip"),
            Pays: stringField("country"),
            téléphone: stringField("phone"),
        },
    },
    contact: {
        logo: LogoContact,
        fields: {
            nom: stringField("name"),
            prénom: {
                type: TYPE_REGISTER_FIELD.NUMBER,
                fieldRegisterName: "name",
            },
        },
    },
}
