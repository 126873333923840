import { useToasts } from "react-toast-notifications"

interface ISettings {
    type?: "error" | "info" | "success" | "warning"
    autoDismiss?: boolean
}

const defaultSettings: Required<ISettings> = {
    type: "info",
    autoDismiss: true,
}

export const useNotification = () => {
    const { addToast } = useToasts()
    return {
        addNotification: (message: string, settings: ISettings = defaultSettings) => {
            const mergedSettings = {
                ...defaultSettings,
                ...settings,
            }

            addToast(message, {
                appearance: mergedSettings.type,
                autoDismiss: mergedSettings.autoDismiss,
            })
        },
    }
}
