import { auth } from "firebase/app"
import { API_MAIN_SERVER_URL } from "config/globals"
import { LOGIN_ERRORS } from "@nectar/lib-errors"
import "firebase/auth"
import axios from "axios"
import { saveToken } from "helpers/jwt/save-token"
import { store } from "redux/store"
import { setConnected } from "redux/profile/profile-action"
import { checkIsConnected } from "../check-connected"

export type loginReturn =
    | {
          status: "LOGGED"
      }
    | {
          status: "REGISTER"
          token: string
      }

export abstract class AbstractLogin {
    protected auth = auth

    constructor() {
        auth().languageCode = "fr"
    }

    public abstract async login(...data: any): Promise<loginReturn>

    protected async loginWithFirebase(
        credentials: auth.UserCredential,
    ): Promise<loginReturn> {
        if (!credentials.user) {
            throw Error("Not a user")
        }

        const token = await credentials.user.getIdToken()

        try {
            const response = await axios.post(API_MAIN_SERVER_URL + "/utils/login", {
                token,
            })

            if (!response.data.token) {
                throw Error("Bad response from server. Can't connect the user")
            }

            saveToken(response.data.token)
            store.dispatch(setConnected(true))
            checkIsConnected()

            return {
                status: "LOGGED",
            }
        } catch (error) {
            if (error.response?.data?.code === LOGIN_ERRORS.USER_NOT_EXISTS) {
                return {
                    status: "REGISTER",
                    token,
                }
            } else if (error.response?.data?.message) {
                throw Error(error.response.data.message)
            } else {
                throw Error(error)
            }
        }
    }
}
