import React, { Suspense, lazy } from "react"
import { Route, useHistory } from "react-router-dom"
import { routes, BASE_URL_PLATFORM } from "helpers/routes"
import { Menu, MenuItem } from "assets/menu"
import { MinimalUserCard } from "assets/user-card"
import DashboardLogo from "assets/logos/LogoDashboard.svg"
import FacturationLogo from "assets/logos/LogoFacturation.svg"
import MissionsLogo from "assets/logos/LogoMissions.svg"
import { useSelector } from "react-redux"
import { StoreType } from "redux/store"
import { USER_TYPE } from "@nectar/lib-types/build/user/user"
import { CircularProgress } from "@material-ui/core"
import { getName } from "helpers/user/get-name"

const Profile = lazy(() => import("./profile/Profile"))
const Mission = lazy(() => import("./missions/mission/Mission"))
const Dashboard = lazy(() => import("./dashboard/Dashboard"))
const Missions = lazy(() => import("./missions/Missions"))
const User = lazy(() => import("./user/User"))

const RoutePlatform: React.FC = () => {
    const profileStore = useSelector<StoreType, StoreType["profile"]>(
        state => state.profile,
    )

    const history = useHistory()

    let userType: string

    let name = getName(profileStore)
    if (profileStore.name && profileStore.lastname) {
        name = `${profileStore.name} ${profileStore.lastname}`
    }

    if (profileStore.userType === USER_TYPE.COMPANY && profileStore.companyName) {
        name = profileStore.companyName
    }

    switch (profileStore.userType) {
        case USER_TYPE.COMPANY:
            userType = "Entreprise"
            break
        case USER_TYPE.FREELANCE:
            userType = "Freelance"
            break
        default:
            userType = "Wizard"
    }

    if (profileStore.connectLoading) {
        return (
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    minHeight: "100vh",
                }}
            >
                <CircularProgress />
            </div>
        )
    }

    if (!profileStore.connected) {
        history.push(routes.login)
        return <div>You are not connected</div>
    }

    return (
        <>
            <Route path={BASE_URL_PLATFORM + "/"}>
                <Menu
                    title="Nectar"
                    links={[
                        {
                            key: "dashboard",
                            item: () => {
                                return (
                                    <MenuItem
                                        image={DashboardLogo}
                                        link={routes.dashboard.index}
                                    >
                                        Dashboard
                                    </MenuItem>
                                )
                            },
                        },
                        {
                            key: "missions",
                            item: () => {
                                return (
                                    <MenuItem
                                        image={MissionsLogo}
                                        link={routes.missions.index}
                                    >
                                        Mes Missions
                                    </MenuItem>
                                )
                            },
                        },
                        {
                            key: "billing",
                            item: () => {
                                return (
                                    <MenuItem image={FacturationLogo}>
                                        Facturation
                                    </MenuItem>
                                )
                            },
                        },
                    ]}
                    bottom={() => {
                        return (
                            <MinimalUserCard
                                name={name}
                                tag={userType}
                                onClick={() => history.push(routes.settings.index)}
                                style={{
                                    cursor: "pointer",
                                }}
                            />
                        )
                    }}
                >
                    <Suspense
                        fallback={
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    minHeight: "100vh",
                                }}
                            >
                                <CircularProgress />
                            </div>
                        }
                    >
                        <Route exac path={routes.settings.index}>
                            <Profile />
                        </Route>
                        <Route exac path={routes.dashboard.index}>
                            <Dashboard />
                        </Route>
                        <Route exac path={routes.missions.index}>
                            <Missions />
                        </Route>
                        <Route exac path={`${routes.missions.mission}/:missionId`}>
                            <Mission />
                        </Route>
                        <Route exac path={`${routes.user.index}/:userId`}>
                            <User />
                        </Route>
                    </Suspense>
                </Menu>
            </Route>
        </>
    )
}

export default RoutePlatform
