import { useEffect, useState } from "react"

export const useInitials = (name: string) => {
    const [initals, setInitials] = useState<string>(name[0] || "")

    useEffect(() => {
        let newInitials = name[0] || ""

        for (let i = name.length - 1; i > 0; i--) {
            if (name[i - 1] === " ") {
                newInitials += name[i]
                break
            }
        }

        setInitials(newInitials)
    }, [name])

    return initals
}
