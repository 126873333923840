import React from "react"
import styles from "./menu-item.module.scss"
import { useHistory } from "react-router-dom"

interface props {
    children: string
    image: any
    link?: string
}

export const MenuItem: React.FC<props> = ({ children, image, link }) => {
    const history = useHistory()

    return (
        <div className={styles.root} onClick={() => link && history.push(link)}>
            <div className={styles.logo}>
                <img src={image} alt="logoMenu" className={styles.image} />
            </div>
            <div className={styles.text}>{children}</div>
        </div>
    )
}
