import { AbstractLogin, loginReturn } from "./abstract-login"

export class FacebookLogin extends AbstractLogin {
    public async login(): Promise<loginReturn> {
        throw Error("Facebook not working now")
        /*
        return {
            status: "LOGGED",
        }
        const facebookProviderAuth = new this.auth.FacebookAuthProvider()
        const userCredentials = await this.auth().signInWithPopup(facebookProviderAuth)
        return this.loginWithFirebase(userCredentials)
        */
    }
}
